import React from "react";
import { Box, Button, Typography } from "@mui/material";
import { styled } from "@mui/system";
import RascoinSvg from "../../../public/main/R.svg";
import { useTranslation } from "react-i18next";

// Styled container for the coin
const CoinContainer = styled(Box)(({ theme }) => ({
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    height: "60vh",
    maxHeight: '400px',
    backgroundColor: theme.palette.background.default,
}));

// Styled coin shape
const CoinShape = styled(Box)(({ theme }) => ({
    width: "220px",
    height: "220px",
    borderRadius: "50%",
    background: `radial-gradient(circle at 30% 30%, #f7f7f7, #e0e0e0, #c0c0c0)`, // Softer, more elegant gradient
    border: "10px solid #d1cfcf", // Light gray border
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    boxShadow: "0px 6px 12px rgba(0, 0, 0, 0.1), inset 0px 6px 8px rgba(255, 255, 255, 0.5)", // Softer shadow
    position: "relative",
}));

// Embossed text around the coin
const EmbossedText = styled(Box)(({ theme }) => ({
    position: "absolute",
    top: "10px",
    width: "100%",
    textAlign: "center",
    fontSize: "14px",
    fontWeight: "bold",
    color: "#3e2f14",
    textShadow: "1px 1px 0px #fff, -1px -1px 0px #3e2f14",
    pointerEvents: "none",
}));

// SVG Logo component
const RascoinLogo = () => (
    <img
        src={RascoinSvg}
        alt="Rascoin Logo"
        style={{ width: "90px", height: "90px" }}
    />
);

const CoinComponent = () => {
    const { t, i18n } = useTranslation();

    return (
        <CoinContainer>
            <CoinShape>
                <EmbossedText></EmbossedText>
                <RascoinLogo />
            </CoinShape>
            <Typography
                sx={{ margin: 3, color: "#8c6e15", textAlign: "center", fontWeight: "bold", backgroundColor: "radial-gradient(circle at 30% 30%, #f7f7f7, #e0e0e0, #c0c0c0)" }}
            >
                {i18n.language === "en" ? " Rascoin : Decentralized Payment for the Rastcom Ecosystem" : "Rascoin : Paiement Décentralisé pour l'Écosystème Rastcom"}
            </Typography>
            <Button href={`${i18n.language}/rascoin`}>{i18n.language === "en" ? "Learn More..." : "En savoir plus..."}</Button>
        </CoinContainer>
    );
};

export default CoinComponent;
