// prosSlice.ts

import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios, { AxiosResponse } from "axios";

const API_URL = process.env.REACT_APP_API_URL;

export interface ProsState {
    responseCategoryCity: any
    loadingPros: boolean;
    errorPros: string | null;
    selectCategoryFooter: string | null;
}

const initialState: ProsState = {
    responseCategoryCity: [],
    loadingPros: false,
    errorPros: null,
    selectCategoryFooter: "",
};

export const getUserProsSlice = createAsyncThunk(
    'user/fetchUserPros',
    () => {
        return axios.get(`${API_URL}/user-profile`, { withCredentials: true })
            .then((response: AxiosResponse<any>) => {
                return response.data;
            })
            .catch((error: any) => {
                throw new Error(error.message);
            });
    }
);

export const userProsSlice = createSlice({
    name: 'pros',
    initialState,
    reducers: {
        setProsCatFooter: (state, action: any) => {
            state.selectCategoryFooter = action.payload;
        },
        resetProsCatFooter: state => {
            state.selectCategoryFooter = "";
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(getUserProsSlice.pending, (state) => {
                state.loadingPros = true;
            })
            .addCase(getUserProsSlice.fulfilled, (state, action: any) => {
                state.loadingPros = false;
                state.responseCategoryCity = action.payload;
            })
            .addCase(getUserProsSlice.rejected, (state, action: any) => {
                state.loadingPros = false;
                state.errorPros = action.payload;
            });
    }
});

export const { setProsCatFooter, resetProsCatFooter } = userProsSlice.actions;
export default userProsSlice.reducer;
